body {
  background-color: #121212;
  color: #ffffff;
}

a {
  color: #ff4040;
}

footer {
  background-color: #1a1a1a;
}

h2 {
  color: #ff4040;
}

button {
  background-color: #ff4040;
  color: white;
}

/* App.css */
.bg-immigration {
  background-image: url('immigration-background.png');
}

@media (max-width: 768px) {
  .bg-immigration {
    background-image: url('./mobile-immigration-background.png');
  }
}



/* App.css */

/* WhatsApp Floating Button */
.fixed {
  position: fixed;
}

.bottom-4 {
  bottom: 1rem;
}

.right-4 {
  right: 1rem;
}

.bg-green-500 {
  background-color: #25D366;
}

.text-white {
  color: #ffffff;
}

.p-4 {
  padding: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.text-2xl {
  font-size: 1.5rem;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

/* Add these styles to your existing CSS */
.modal-open {
  overflow: hidden;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modal-content {
  animation: modalFadeIn 0.2s ease-out;
}